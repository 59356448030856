<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Knowledgebase
			</div>
		</div>
		<div>
			<list-view :list="list" />
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isAdminOnly": true
		}
	}
</route>

<script>
	import ListView    from '@/components/ui/views/listView/ListView';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Knowledgebase'
		},
		components: {
			ListView
		},
		data: () => ({
			list: [
				{
					text: 'Categories',
					route: '/admin/knowledgebase/categories'
				},
				{
					text: 'Articles',
					route: '/admin/knowledgebase/articles'
				}
			]
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/knowledgebase',
						text: 'Knowledgebase'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		}
	};

</script>
